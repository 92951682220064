import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import LinkWrap from 'components/LinkWrap'
import { textAddSpans } from 'js/utils'
import Inview from 'components/Inview'

const Block = ({ content, locale }) => {
  const { title, productCategorySlugs } = content
  const titleSpan = textAddSpans(title)
  let titleSpanPre = titleSpan
  if (productCategorySlugs.includes('riderefine')) {
    titleSpanPre = `RideRefine ${titleSpanPre}`
  }

  const strings = {
    en: {
      products: 'Products',
    },
    zh: {
      products: '产品',
    },
  }

  return (
    <Inview className={`row ${styles.productAnim} in in-fade-in-up-100`}>
      <div className='col-10 offset-1'>
        <div className={styles.fil}>
          <LinkWrap to='/'>{strings[locale].products}</LinkWrap>
          <i className='fal fa-chevron-right'></i>
          <a dangerouslySetInnerHTML={{ __html: titleSpanPre }} />
        </div>
      </div>
    </Inview>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment BreadcrumbEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment BreadcrumbZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
