import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'

const Block = ({ content }) => {
  const { title, body } = content
  return (
    <div className='row'>
      <div className='offset-lg-1 col-10'>
        <div className={styles.listProduct}>
          <p className={styles.title}>{title}</p>
          <div className={styles.body}>
            <MarkdownWrap body={body} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextOnlyEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    items {
      body
    }
  }
`
export const fragmentZh = graphql`
  fragment TextOnlyZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    items {
      body
    }
  }
`
