import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import Footer from 'components/Footer'
import * as styles from './styles.module.scss'
import Breadcrumb from 'blocks/Breadcrumb'

// IMPORTING BLOCKS
// 1) add to imports
// 2) add to components object
// 3) add to graphQL

import GridVideosInner from 'blocks/GridVideosInner'
import TextOnly from 'blocks/TextOnly'
import TextNotes from 'blocks/TextNotes'
import TextOffsetImage from 'blocks/TextOffsetImage'

// [name of block type]: [Imported Component]
// ['TextCentered']: TextCentered

const components = {
  GridVideosInner,
  TextOnly,
  TextOffsetImage,
  TextNotes,
}

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page } = data
  const content = page.frontmatter[locale]
  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

// from CMS: go directly to Page Template

const PageTemplate = ({ content, locale, layoutProps }) => {
  const { title, productCategorySlugs, blocks } = content
  const breadcrumbContent = {
    title,
    productCategorySlugs,
  }
  return (
    <div
      className={`${styles.page} page ${styles.product} ${styles.passiveProduct}`}
    >
      <div className='container'>
        <Breadcrumb content={breadcrumbContent} locale={locale} />
        {blocks?.map((block, i) => {
          if (components[block.type]) {
            return React.createElement(components[block.type], {
              content: block,
              locale,
              layoutProps,
              key: i,
            })
          }
        })}
      </div>
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          title
          productCategorySlugs
          blocks {
            type
            ...GridVideosInnerEn
            ...TextOnlyEn
            ...TextNotesEn
            ...TextOffsetImageEn
          }
          seo {
            title
            description
            keywords
          }
        }
        zh {
          title
          productCategorySlugs
          blocks {
            type
            ...GridVideosInnerZh
            ...TextOnlyZh
            ...TextNotesZh
            ...TextOffsetImageZh
          }
          seo {
            title
            description
            keywords
          }
        }
      }
    }
  }
`
