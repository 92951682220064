import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import GridVideos from 'blocks/GridVideos'

const Block = ({ content, locale }) => {
  const { items } = content
  const gridContent = {
    items,
  }

  const strings = {
    en: {
      videos: 'Videos',
    },
    zh: {
      videos: '视频',
    },
  }

  return (
    <>
      <div className='row'>
        <div className='offset-lg-1 col-10'>
          <div className={styles.listProduct}>
            <p>
              <b>{strings[locale].videos}</b>
            </p>
          </div>
        </div>
      </div>
      <GridVideos content={gridContent} locale={locale} />
    </>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment GridVideosInnerEn on MarkdownRemarkFrontmatterEnBlocks {
    items {
      title
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      videoSlug
    }
  }
`
export const fragmentZh = graphql`
  fragment GridVideosInnerZh on MarkdownRemarkFrontmatterZhBlocks {
    items {
      title
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      videoSlug
    }
  }
`
